import React, { Component } from 'react';
import useOsContext from './useOsContext';

const withWppContext = (Component) => {    
    return function WrappedComponent(props) {
        const wppOsContextHook = useOsContext();
        return <Component {...props} wppOsContextHook = {wppOsContextHook} />;
    };
};

export default withWppContext;
