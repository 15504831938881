import React from 'react';
import { connect } from 'react-redux';
import {
    userActions,
    userService,
    PasswordInputs,
    alertActions,
    SupportContactArea,
} from '../../../components';
import { utilService, history } from '../../../_helpers';
import './UserLoginPage.scss';
import withNoAuthNav from '../../_shared/Nav/withNoAuthNav';

const initialValidationState = {
    password: {
        validated: true,
        message: '',
    },
    confirmPassword: {
        validated: true,
        message: '',
    },
};
class UserRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            validations: initialValidationState,
            submitted: false,
            user: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        debugger;
        document.title = 'Brandshare Collaboration - Create Account';
        this.props.dispatch(userActions.logout());

        const { data } = this.props.location;
        if (data && data.user) {
            this.setState({ user: data.user });
        } else {
            history.push('/home');
        }
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value, submitted: false });
    }

    async validateFields() {
        var globalValidation = true;
        var { validations } = this.state;
        var validationCopy = { ...validations };

        if (
            !this.state.password ||
            (this.state.password && userService.validatePassword(this.state.password))
        ) {
            validationCopy.password = {
                validated: false,
                message: "Password complexity didn't match ",
            };
            globalValidation = false;
        }
        if (
            !this.state.confirmPassword ||
            (this.state.confirmPassword && this.state.password !== this.state.confirmPassword)
        ) {
            validationCopy.confirmPassword = {
                validated: false,
                message: "Password didn't match ",
            };
            globalValidation = false;
        }
        await utilService.setStateAsync.bind(this)({
            validations: validationCopy,
        });
        return globalValidation;
    }

    async handleSubmit(e) {
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
        });
        var allValidated = await this.validateFields();
        if (!allValidated) {
            return;
        }
        const { password, confirmPassword, user } = this.state;
        if (password && confirmPassword && user) {
            var requestData = {
                id: user.id,
                password: password,
                inviteCode: user.inviteCode,
            };
            await userService.registerAccount(requestData);
            this.props.dispatch(alertActions.success('Done !'));
            history.push('/login');
        }
    }

    async handleSubmitWPPOpen(e) {
        debugger;
        e.preventDefault();
        await utilService.setStateAsync.bind(this)({
            submitted: true,
            validations: initialValidationState,
        });
        
        const { user } = this.state;
        if ( user) {
            var requestData = {
                id: user.id,                
                inviteCode: user.inviteCode,
            };
            await userService.registerAccountWPPOpen(requestData);
            this.setState({
                wppAccountRegCompleted: true,
            });
            this.props.dispatch(alertActions.success('Done !'));            
        }
    }

    render() {
        const { password, confirmPassword, submitted, validations, user } = this.state;
        return (
            <div className="mrnda-main-content padding-2x">
                <div className="mrnda-flex-center-wrapper">
                    <div className="sign-in-box-wrapper">
                        <div className="tenant-logo margin-2x--bottom typography--center">
                            <div>
                                {/* <i style={{ color: '#fc275c' }} className="fab fa-mix fa-7x"></i> */}
                                  <img src='../../assets/Images/brand_logo.png'></img>
                            </div>
                        </div>
                        <div className="sign-in-box mrnda-card padding-3x margin-4x--bottom">
                           { (this.state.user.authProvider) &&
                                <>
                                   { 
                                        this.state.user.authProvider === 5? 
                                        <div>
                                            <div className="control-group">
                                                <div className="controls">
                                                    {this.state.wppAccountRegCompleted ? <div style={{width: '310px', textAlign: 'center'}}>
                                                        Your account has been created. Please go to WPP Open to start using Brandshare Collaboration
                                                    </div> :
                                                    <button onClick={this.handleSubmitWPPOpen.bind(this)} className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                        Accept account
                                                    </button>}
                                                </div>
                                            </div>
                                        </div>
                                        : <>
                                        <h1 className="margin-2x--bottom text--center">Create password</h1>
                                     <div className="tile-message">
                                         <form className="" name="form" onSubmit={this.handleSubmit}>
                                             <div className="form-horizontal">
                                                 <div className="form-horizontal">
                                                     <p
                                                         style={{
                                                             opacity: '.8',
                                                             fontSize: '12px',
                                                             textAlign: 'center',
                                                             width: '310px',
                                                             marginBottom: '10px',
                                                         }}>
                                                         for your account <br />
                                                         <strong>{user.email}</strong>
                                                     </p>
                                                     <PasswordInputs
                                                         submitted={submitted}
                                                         passwordValue={password}
                                                         confirmPasswordValue={confirmPassword}
                                                         validations={validations}
                                                         onChange={this.handleChange}
                                                     />
                                                     <div className="control-group">
                                                         <div className="controls">
                                                             <button className="mrnda-btn mrnda-btn--full margin-2x--bottom">
                                                                 Create Account
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         </form>
                                         <p className="text sm-forward typography--center">
                                             <a className="typography--small" href=""></a>
                                         </p>
                                     </div>
                                      </>
                                   }
                                </>
                           }
                        </div>
                        <SupportContactArea />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
  return {};
}

const connectedUserRegister = withNoAuthNav(connect(mapStateToProps)(UserRegister));
export { connectedUserRegister as UserRegister };
