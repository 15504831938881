import { useEffect, useState } from 'react';
import { DummyContext } from './WppConstants';
import { connectToParent } from 'penpal';
import { WPP_Context_Receive_Status } from './WppConstants';
const simulation = true;

const useOsContext = () => {    
    const [osContext, setOsContext] = useState(null);
    const [isContextPresent, setIsContextPresent] = useState(false);

    useEffect(() => {        
        onLoaded();
    }, []);

    const onContextReceived = async (osContext) => {
        setOsContext(osContext);
        setIsContextPresent(true);
        // setIsContextPresent(false);
    };

    const onLoaded = async () => {
        console.log("On use wpp hook");
        if (simulation === true) {   
                debugger;
                var context = localStorage.getItem("DummyContext");
                var contextObj = JSON.parse(context);
                if (contextObj && contextObj.userDetails) {
                    onContextReceived(contextObj);
                }                        
        } else {
            connectToParentContext();
        }
    };

    const connectToParentContext = () => {
        const connection = connectToParent({
            parentOrigin: '*',
            methods: {
                receiveOsContext: (osContext) => {
                    console.log('WPP Open context received');
                    if (osContext) {
                        console.log('WPP Open context received');
                    }
                    onContextReceived(osContext);
                }
            },
            debug: true,
            timeout: 1000
        });

        connection.promise.then(({ osApi }) => {    
            console.log('WPP Open context received');        
            osApi.getAccessToken().then((value) => {
                if (value) {
                    console.log('WPP Open context received');
                }
            });
        });

        connection.promise.catch((error) => {
            console.log('Penpal child context error:', error);
            // setOsContext(undefined);
            // setIsContextPresent(false);
            // // setIsContextPresent(false);
        });
    };

    return { isContextPresent, osContext };
};

export default useOsContext;
