import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { groupActions, userActions, alertActions, MultiSelect } from '../..';
import { utilService } from '../../../_helpers';
import { groupService, userService } from '../../User';

const initialValidationState = {
    name: {
        validated: true,
        message: ''
    },
    role: {
        validated: true,
        message: ''
    }
};

// class DomainConfigurator extends React.Component {
//     constructor(props) {
//         super(props);
//          state = {
//             validations: ,
//             users: [],
//             canSave: ,
//             selectedRole: null
//         };
//          handleChange =  handleChange.bind(this);
//          handleSubmit =  handleSubmit.bind(this);
//     }

// }

const DomainConfigurator = ({
    onUpdateDomainConfiguration,
    groupObject,
    setGroupObject,
    operationMode,
    ...props
}) => {
    const [validations, setValidations] = useState(initialValidationState);
    const [selectedGroups, setSelectedGroups] = useState(undefined);
    const [selectedRole, setSelectedRole] = useState(undefined);    
    const [canSave, setCanSave] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (operationMode === 2) {
            setUpSelectedGroupsAndRoles();
        }
    }, [operationMode]);

    useEffect(() => {
        setUpSelectedGroupsAndRoles();
    }, [groupObject.id]);
    const setUpSelectedGroupsAndRoles = () => {
        let currentRole = {};
        currentRole = props.roles.find((x) => x.id == groupObject.roleId);
        setSelectedRole(currentRole);

        let currentlySelected = [];
        currentlySelected = props.groups.filter((group) => groupObject.groups?.includes(group.id));

        setSelectedGroups(currentlySelected);
    };

    const handleChange = (event) => {
        var { name, value } = event.target;

        if (name === 'isEnabled') {
            value = value == 'false' ? 'Enabled' : 'Disabled';
        }

        setGroupObject({
            ...groupObject,
            [name]: value
        });

        valueChanged();
    };

    const valueChanged = () => {
        setSubmitted(false);
        setCanSave(true);
    };

    const onChangeRole = async (selectedOption) => {
        setGroupObject({
            ...groupObject,
            selectedRole: selectedOption
        });
        setSelectedRole(selectedOption);
        valueChanged();
    };

    const onChangeGroup = async (selectedOptions) => {
        var selectedGroupIds = selectedOptions
            ? selectedOptions.map((item) => {
                  var newItem = { ...item };
                  return newItem.id;
              })
            : [];

        var newGroupObject = {
            ...groupObject,
            groups: selectedOptions,
            selectedGroupIds : selectedGroupIds           
        };
        setGroupObject(newGroupObject);
        setSelectedGroups(selectedOptions);
    };

    const isValidName = (name) => {
        if (name.toLowerCase() == "default") {
            return true;
        }
        return !userService.validateDomain(name);
    }

    const validateFields = async () => {
        var globalValidation = true;
        var validationCopy = { ...groupObject };
        let name = validationCopy.name;
        debugger;
        if (!name || (name && name.length < 3 || !isValidName(name))) {
            validationCopy.name = {
                validated: false,
                message: 'Enter a valid domain'
            };
            globalValidation = false;
        } else if (!props.isUniqueDomain(name, validationCopy.id, operationMode)) {
            validationCopy.name = {
                validated: false,
                message: 'Domain exists'
            };
            globalValidation = false;
        } 
        else {
            validationCopy.name = initialValidationState.name;
        }

        let role = selectedRole;

        if (!role) {
            validationCopy.role = {
                validated: false,
                message: 'Please select a role'
            };
            globalValidation = false;
        } else {
            validationCopy.role = initialValidationState.role;
        }

        setValidations(validationCopy);

        return globalValidation;
    };

    const validateName = async (name) => {
        var globalValidation = true;

        await setValidations((validationCopy) => {
            return validationCopy;
        });
        return globalValidation;
    };

    const validateRole = async (role) => {
        var globalValidation = true;

        await setValidations((validationCopy) => {
            if (!role) {
                validationCopy.role = {
                    validated: false,
                    message: 'Please select a role'
                };
                globalValidation = false;
            } else {
                validationCopy = initialValidationState.role;
            }
            return validationCopy;
        });
        return globalValidation;
    };

    // const validateRole = async (role) => {

    //     var globalValidation = true;
    //     var validationCopy = { ...validations };
    //     if (!role) {
    //         validationCopy.description = {
    //             validated: false,
    //             message: 'Please select a role'
    //         };
    //         globalValidation = false;
    //     }

    //     await setValidations({
    //         ...validationCopy
    //     });
    //     return globalValidation;
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        debugger;
        setSubmitted(true);
        setValidations(initialValidationState);
        setCanSave(true);

        if (!(await validateFields())) {
            return;
        }

        let success = onUpdateDomainConfiguration({ ...groupObject }, operationMode);
        if (success === true) {
            clearValues();
        }
    };

    const update = (domain) => {
        console.log('Domain will be updated');
        console.log(domain);
    };

    const add = (domain) => {
        console.log('Domain will be added');
        console.log(domain);
    };

    const onDiscard = (domain) => {
        console.log("domain edit clicked");
        clearValues();        
    }

    const clearValues = () => {
        if (operationMode !== 1) {
            props.setDomainConfigMode(1);
        }
        setGroupObject({});
        setSelectedGroups([]);
        setSelectedRole([]);
    }

    return (
        <div style={{ marginBottom: '20px', marginTop: '20px', minHeight: '90x' }}>
            <form style={{ display: 'flex' }} name="form" onSubmit={handleSubmit}>
                <div
                    style={{ flex: '1' }}
                    className={
                        'add-domain-row ' +
                        'form-group' +
                        (submitted && !groupObject.firstName ? ' has-error' : '')
                    }>
                    <label htmlFor="name"> Domain Name</label>
                    <input
                        type="text"
                        className="form-control mrnda-input-text"
                        name="name"
                        value={groupObject.name || ''}
                        disabled = {groupObject.isDefault}
                        onChange={handleChange}
                    />
                    {submitted && !validations.name?.validated && (
                        <div className="help-block">{validations.name?.message}</div>
                    )}
                </div>
                <div
                    style={{ flex: '1' }}
                    className={
                        'add-domain-row ' +
                        'form-group' +
                        (submitted && !groupObject.role ? ' has-error' : '')
                    }>
                    <label htmlFor="role">Select Role</label>

                    <MultiSelect
                        isMulti={false}
                        options={props.roles}
                        selectedOptions={selectedRole}
                        onChange={onChangeRole}
                        closeMenuOnSelect={true}
                    />
                    {submitted && !validations.role?.validated && (
                        <div className="help-block">{validations.role?.message}</div>
                    )}
                </div>
                <div
                    style={{ flex: '1' }}
                    className={
                        'add-domain-row ' +
                        'form-group' +
                        (submitted && !groupObject.description ? ' has-error' : '')
                    }>
                    <label htmlFor="description">Select groups</label>
                    {true /*  existingUsers.length > 0*/ && (
                        <MultiSelect
                            isMulti={true}
                            options={props.groups}
                            selectedOptions={selectedGroups}
                            onChange={onChangeGroup}
                            closeMenuOnSelect={true}
                        />
                    )}
                </div>
                <div
                    className={
                        'form-group d-none' +
                        (submitted && !groupObject.firstName ? ' has-error' : '')
                    }>
                    <label htmlFor="status">Enabled</label>
                    <input
                        type="checkbox"
                        className="ml-2"
                        data-toggle="toggle"
                        data-on="Enabled"
                        data-off="Disabled"
                        name="isEnabled"
                        value={groupObject.isEnabled === 'Enabled' ? true : false}
                        checked={groupObject.isEnabled === 'Enabled' ? true : false}
                        onChange={handleChange}
                    />
                </div>

                <Button
                    style={{ marginTop: '26px' }}
                    className="btn mrnda-btn"
                    onClick={handleSubmit}
                    disabled={!canSave}>
                    {operationMode === 1 ? 'Add' : 'Update'}
                </Button>
                {operationMode === 2 && (
                    <Button
                        style={{ marginLeft: '8px', marginTop: '26px' }}
                        className="mrnda-btn--caution"
                        onClick={onDiscard}>
                        Discard
                    </Button>
                )}
            </form>
        </div>
    );
};

export { DomainConfigurator };
