import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history, utilService } from '../../../_helpers';
import { userService } from '../../../components';
import { Alert } from 'react-bootstrap';
import withNoAuthNav from '../../_shared/Nav/withNoAuthNav';

class UserRegisterRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nextLocation: {},
            code: null,
            user: {},
        };
        this.currentPathname = null;
        this.currentSearch = null;
    }
    async componentDidMount() {
        debugger;
        var code = utilService.getQueryStringValue(
            'code',
            window.location.search,
            window.location.href,
        );

        var response = await userService.validateInvite(code);
        var { user } = response;

        await utilService.setStateAsync.bind(this)({ user });

        // 2 = invalid code
        // 3 = not found user
        // 4 = already registered
        // 5 = link expired
        // 6 = OktaGateway provider 

        switch (response.resultType) {
            case 1:
                {
                    user.inviteCode = code;
                    history.replace({
                        pathname: `/createaccount`,
                        data: { user: user },
                    });
                }
                break;
            case 2:
            case 3:
                {
                    history.replace({
                        pathname: `/login`,
                        data: { redirectMessage: 'User not found' },
                    });
                }
                break;
            case 4:
                {
                    history.replace({
                        pathname: `/login`,
                        data: { redirectMessage: 'User already registered' },
                    });
                }
                break;
            case 5:
                {
                    history.replace({
                        pathname: `/login`,
                        data: { redirectMessage: 'Link expired' },
                    });
                }
                break;
            case 6:
                {
                    history.replace({
                        pathname: `/landing`,
                        data: { redirectMessage: '', isOktaGatewayRedirection: true  },
                    });
                }
                break;
            case 7:
                {
                    history.replace({
                        pathname: `/landing`,
                        data: { redirectMessage: '', isOktaRedirection: true  },
                    });
                }
                break;
            default:
                {
                    history.replace({
                        pathname: `/login`,
                        data: { redirectMessage: 'Something went wrong !' },
                    });
                }
                break;
        }
    }
    render() {
        return (
            <div
                className="main-content d-flex justify-content-center align-items-center"
                style={{
                    top: 80,
                }}>
                <div
                    className="spinner-grow text-danger m-2"
                    role="status"
                    style={{ width: '3.5rem', height: '3.5rem' }}></div>
                <h1>Loading...</h1>
            </div>
        );
    }
}

function mapStateToProps(state) {
   return {};
}

const connectedRegisterPage = withNoAuthNav(connect(mapStateToProps)(withRouter(UserRegisterRequest)));
export { connectedRegisterPage as UserRegisterRequest };
