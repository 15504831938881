import React, { useState, useEffect } from 'react';
import { Button, Modal, ProgressBar, Table } from 'react-bootstrap';

import { LOGIN_PROVIDER_LABEL } from '../LoginSettingManage';
import { CheckboxInput } from '../CheckboxInput';
import { DomainConfigurator } from './DomainConfigurator';
import { WPPOpenDomain } from './WPPOpenDomain';
import { store, utilService } from '../../../_helpers';
import { alertActions, tenantService } from '../..';
import WPPOpenLoginConfig from './WPPOpenLoginConfig';



export function WPPOpenLogin({
    authURLMessage,
    authURLValidated,
    submitted,
    handleChange,
    authURL,
    selectedRole,
    roles,
    groups,
    canSave,
    enabled,
    fetchData,
    ...props
}) {
    
    const [showWPPOpenLoginModal, setShowWPPOpenLoginModal] = useState(false);
    
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <label
                    style={{
                        margin: '0 20px 0 0',
                        fontSize: 16
                    }}
                    className="check-container">
                    <span className="text--small display--block role-permission-section__body__check-label">
                        <strong>{LOGIN_PROVIDER_LABEL.wppopen}</strong>
                    </span>
                    <input
                        type="checkbox"
                        className="ml-2 mr-2"
                        data-toggle="toggle"
                        data-on="Enabled"
                        data-off="Disabled"
                        name={'enabled'}
                        value={'marcombox'}
                        checked={enabled}
                        onChange={async (event) => {
                            const { checked } = event.target;
                            handleChange(event);
                            setShowWPPOpenLoginModal(true);
                        }}
                    />
                    <span className="check-container__checkmark">
                        <i className="fas fa-check" />
                    </span>
                </label>
                <button
                    style={{
                        fontSize: 10,
                        padding: '2px 8px',
                        height: 'auto',
                        marginTop: 2
                    }}
                    className="mrnda-btn--secondary btn-sm btn btn-primary"
                    onClick={() => {
                        setShowWPPOpenLoginModal(true);
                    }}>
                    <span className="">Configure</span>
                </button>
            </div>

            {showWPPOpenLoginModal && <WPPOpenLoginConfig
            
            {...{showWPPOpenLoginModal,
                setShowWPPOpenLoginModal,                 
                enabled,
                canSave,
                fetchData,
                groups,
                roles,
                ...props
               }}
            ></WPPOpenLoginConfig>}            
        </>
    );
}
